
import { withRouter, Link, Route, HashRouter as Router, Switch } from 'react-router-dom'
import Bundle from './Bundle.jsx'

let test = function () {
  return <Bundle load={() => import('./pages/TestTest')}>
    {(Com) => <Com />}
  </Bundle>;
};
let erweima = function () {
  return <Bundle load={() => import('./pages/erweima')}>
    {(Com) => <Com />}
  </Bundle>;
};
let countdown = function () {
  return <Bundle load={() => import('./pages/countdown')}>
    {(Com) => <Com />}
  </Bundle>;
};
let countdownResult = function () {
  return <Bundle load={() => import('./pages/countdownResult')}>
    {(Com) => <Com />}
  </Bundle>;
};

function App(props) {
  return (
    <>
      <Router>
        <Switch>
          <Route path="/" component={test} exact />
          <Route path="/erweima" component={erweima} />
          <Route path="/countdown" component={countdown} />
          <Route path="/countdownResult" component={countdownResult} />
        </Switch>
      </Router>
    </>
  );
}

export default App;